<template>
  <details
    v-if="$showDevThings"
    class="dev-dump"
    :style="`--dev-dump-height: ${minHeight}px`">
    <summary>
      <div class="dev-dump__btn">
        <span>🦄 {{ label }}</span>
        <v-icon>chevron_right</v-icon>
      </div>
    </summary>

    <div class="dev-dump__container">
      <pre v-html="$sanitizeHtml(parsedCode)"/>
    </div>
  </details>
</template>

<script>
//! Probably should not commit these, as we don't commit console.logs, which are similar.
export default {
  name: 'CyDevDump',
  props: {
    code: {
      type: null,
      required: true,
    },
    label: {
      type: String,
      default: 'Dump',
    },
    minHeight: {
      type: [Number, String],
      default: 400,
    },
  },
  computed: {
    parsedCode () {
      return JSON.stringify(this.code ?? '', null, 2)
        .replaceAll(/"(.+)":/g, `<span class="field-name">$1</span>:`)
        .replaceAll(/:\s"(.*)"/g, ': <span class="value-string">"$1"</span>')
        .replaceAll(/([[\]{}])/g, `<span class="bracket">$1</span>`)
        .replaceAll(/(: |,)/g, `<span class="separator">$1</span> `)
    },
  },
}
</script>

<style lang="scss" scoped>
.dev-dump {
  z-index: 2;
  margin: 0.5em;

  summary {
    list-style: none;
  }

  &[open] {
    summary .v-icon {
      rotate: 90deg;
    }
  }

  &__btn {
    display: inline-flex;
    width: auto;
    padding: 4px 8px 4px 12px;
    border: 1px solid cy-get-color("grey");
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus {
      @extend %rainbow-background;
    }
  }

  &__container {
    min-height: var(--dev-dump-height);
    padding: 1em;
    border-radius: 4px;
    background-color: cy-get-color("grey", "dark-4");
    color: #2b75ed;

    pre::selection {
      background: #666;
    }

    ::v-deep {
      .field-name {
        color: #54c8f3;
      }

      .separator {
        color: #fff;
      }

      .value-string {
        color: #d38944;
      }

      .bracket {
        color: #d06bd7;
      }
    }
  }
}
</style>
